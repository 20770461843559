import React from 'react';
import type {
  ContentTilesProps,
  ContentTilesTile,
} from '@noths/polaris-client-ribbons-design-system';
import { ContentTiles } from '@noths/polaris-client-ribbons-design-system';
import { getContentstackImageURL } from '@noths/polaris-client-utils';

import { SectionContainer } from 'src/components/molecules/SectionContainer/SectionContainer';
import type { ContentTileSerializedTile } from 'src/content/sections/contentTiles/types';
import type { ItemsTrackingContext } from 'src/tracking/common/trackItemsImpression';
import { trackItemsImpression } from 'src/tracking/common/trackItemsImpression';
import { trackLinkClick } from 'src/tracking/common/trackLinkClick';
import * as styles from './ContentTilesSection.style';

export interface ContentTilesSectionProps extends Omit<ContentTilesProps, 'tiles'> {
  tiles: ContentTileSerializedTile[];
  trackingPosition?: number;
  trackingTitle: string;
}

export const ContentTilesSection = ({
  tiles,
  trackingPosition,
  trackingTitle,
}: ContentTilesSectionProps) => {
  const trackingContext: ItemsTrackingContext = {
    trackingPosition,
    trackingTitle,
    trackingContentType: 'Content tiles',
    numberOfItems: tiles.length,
  };

  return (
    <SectionContainer columnsXL={10} maxWidth="full" wrapperStyles={styles.wrapper}>
      <ContentTiles
        onTileClick={(e, { index }) => {
          const tile = tiles[index];

          trackLinkClick(e, {
            ...trackingContext,
            link: { index, name: tile.title, href: e.currentTarget.href },
            actionName: 'Tile click',
          });
        }}
        onVisible={() => tiles.length > 0 && trackItemsImpression(trackingContext)}
        tiles={tiles.map(
          ({ imageSrc, ...tile }) =>
            ({
              ...tile,
              // @ts-ignore - Could not resolve this error: "Type error: Binding element 'width' implicitly has an 'any' type."
              imageSrc: ({ aspectRatio, height, width }) => {
                return imageSrc
                  ? getContentstackImageURL(imageSrc, width, { aspectRatio, height })
                  : null;
              },
            } as ContentTilesTile),
        )}
      />
    </SectionContainer>
  );
};

export type { ContentTilesProps };
