import { sendGALinkClickEvent } from '@noths/polaris-client-google-analytics';

import type { TrackingContext } from 'src/types/TrackingContext';

interface Link {
  href: string;
  index: number;
  name: string;
}

type ActionName = 'Category click' | 'Department click' | 'Tile click';

export interface LinkClickTrackingContext extends TrackingContext {
  actionName: ActionName;
  link: Link;
  numberOfItems: number;
}

export const trackLinkClick = (
  e: React.MouseEvent<HTMLAnchorElement>,
  {
    actionName,
    link: { href, index, name },
    numberOfItems,
    trackingContentType,
    trackingPosition,
    trackingTitle,
  }: LinkClickTrackingContext,
) => {
  sendGALinkClickEvent(e, {
    event: 'custom_event',
    event_action: `${actionName}`,
    event_category: `${trackingPosition} | ${trackingContentType}`,
    event_label: `${trackingPosition} | ${trackingTitle} | ${index + 1} | ${name}`.toLowerCase(),
    event_name: 'select_content',
    event_details: `${actionName}`.toLowerCase(),
    content_position: `${trackingPosition}`,
    content_type: `${trackingContentType}|${trackingTitle}`.toLowerCase(),
    total_options: `${numberOfItems}`,
    selected_position: `${index + 1}`,
    item_id: `${name}`.toLowerCase(),
    destination_URL: `${window.location.origin}${href}`,
  });
};
