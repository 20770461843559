import { sendGAEvent } from '@noths/polaris-client-google-analytics';

import type { TrackingContext } from 'src/types/TrackingContext';

export interface ItemsTrackingContext extends TrackingContext {
  numberOfItems: number;
}

export const trackItemsImpression = ({
  numberOfItems,
  trackingContentType,
  trackingPosition,
  trackingTitle,
}: ItemsTrackingContext) => {
  sendGAEvent({
    event: 'custom_event',
    event_action: 'Impression',
    event_category: `${trackingPosition} | ${trackingContentType}`,
    event_label: `${trackingPosition} | ${trackingTitle} | ${numberOfItems}`.toLowerCase(),
    event_name: 'view_content',
    content_position: `${trackingPosition}`,
    content_type: `${trackingContentType}|${trackingTitle}`.toLowerCase(),
    total_options: `${numberOfItems}`,
  });
};
